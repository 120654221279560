import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "../../components/layout";
import SearchEngineOptimization from "../../components/SearchEngineOptimization";
import ArticleList from "../../components/ArticleList";
import FeaturedArticle from "../../components/FeaturedArticle";

const NewsListing = () => {
	const data = useStaticQuery(graphql`
		query {
			allContentfulArticle(sort: { fields: publishedDate, order: DESC }) {
				edges {
					node {
						title
						slug
						previewImage {
							gatsbyImageData(
								placeholder: BLURRED
								formats: [AUTO, WEBP]
								aspectRatio: 1.64
							)
							description
							title
						}
						heroImage {
							gatsbyImageData(
								placeholder: BLURRED
								formats: [AUTO, WEBP]
								aspectRatio: 1.64
							)
							description
							title
						}
						category {
							title
						}
						excerpt {
							excerpt
						}
						tags
					}
				}
			}
		}
	`);

	const {
		allContentfulArticle: { edges: allArticles },
	} = data;

	return (
		<Layout>
			<SearchEngineOptimization title="Irish Titan News" />
			<FeaturedArticle />
			<section className="section">
				<div className="container">
					<ArticleList articles={allArticles} />
				</div>
			</section>
		</Layout>
	);
};

export default NewsListing;
