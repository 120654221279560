import React, { useEffect, useState } from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import ArticleCard from "../ArticleCard";
import "./styles.scss";

function ArticleList({ articles }) {
	const data = useStaticQuery(graphql`
		query {
			allContentfulArticleCategory {
				edges {
					node {
						id
						title
						slug
					}
				}
			}
		}
	`);

	const {
		allContentfulArticleCategory: { edges: allCategories },
	} = data;

	// Set up the "load more" data
	const NUM_OF_ARTICLES_TO_LOAD = 30;
	const [articlesToDisplay, setArticlesToDisplay] = useState([
		...articles.slice(0, NUM_OF_ARTICLES_TO_LOAD),
	]);
	const [loadMoreArticles, setLoadMoreArticles] = useState(false);
	const [hasMoreArticles, setHasMoreArticles] = useState(
		articles.length > NUM_OF_ARTICLES_TO_LOAD
	);

	const handleLoadMoreArticles = () => {
		setLoadMoreArticles(true);
	};

	// Handle loading more articles
	useEffect(() => {
		if (loadMoreArticles && hasMoreArticles) {
			const currentLength = articlesToDisplay.length;
			const isMore = currentLength < articles.length;
			const nextSetOfResults = isMore
				? articles.slice(currentLength, currentLength + NUM_OF_ARTICLES_TO_LOAD)
				: [];
			setArticlesToDisplay([...articlesToDisplay, ...nextSetOfResults]);
			setLoadMoreArticles(false);
		}
	}, [loadMoreArticles, hasMoreArticles]); //eslint-disable-line

	// Check if there is more articles available to load
	useEffect(() => {
		const isNotAtEndOfArticleList = articlesToDisplay.length < articles.length;
		setHasMoreArticles(isNotAtEndOfArticleList);
	}, [articlesToDisplay]); //eslint-disable-line

	return (
		<div className="article-list">
			<h1>
				Titan Musings <span className="text--orange">News</span>
			</h1>
			<div className="article-list__categories">
				<Link
					className={`article-list__category`}
					to={`/news`}
				>
					All
				</Link>

				{allCategories.map(category => {
					const { title, id, slug } = category.node;

					return (
						<Link
							key={id}
							to={`/news/${slug}`}
							className={`article-list__category`}
						>
							{title}
						</Link>
					);
				})}
			</div>
			<div className="card-grid">
				{articlesToDisplay.map(article => {
					const {
						title,
						previewImage,
						heroImage,
						category,
						slug,
						excerpt: { excerpt },
					} = article.node;

					return (
						<ArticleCard
							key={title}
							previewImage={previewImage}
							heroImage={heroImage}
							category={category.title}
							title={title}
							excerpt={excerpt}
							slug={slug}
						/>
					);
				})}
			</div>
			<div className="article-list__load-more">
				{hasMoreArticles && (
					<button className="btn btn--black-green" onClick={handleLoadMoreArticles}>
						Load More Articles
					</button>
				)}
			</div>
		</div>
	);
};

export default ArticleList;

ArticleList.propTypes = {
	articles: PropTypes.arrayOf(
		PropTypes.shape({
			node: PropTypes.shape({
				category: PropTypes.shape({
					title: PropTypes.string,
				}),
				excerpt: PropTypes.shape({
					excerpt: PropTypes.string,
				}),
				previewImage: PropTypes.object,
				slug: PropTypes.string,
				tags: PropTypes.arrayOf(PropTypes.string),
				title: PropTypes.string,
			}),
		})
	),
};
