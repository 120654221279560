import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import "./styles.scss";

function FeaturedArticle() {
	const featuredArticle = useStaticQuery(graphql`
		query {
			contentfulFeaturedArticle(listingPage: { eq: "News" }) {
				listingPage
				article {
					title
					slug
					heroImage {
						file {
							url
						}
					}
					category {
						title
					}
					excerpt {
						excerpt
					}
					tags
				}
			}
		}
	`);

	const {
		category: { title: featuredArticleCategory },
		heroImage,
		slug: featuredArticleSlug,
		title: featuredArticleTitle,
	} = featuredArticle.contentfulFeaturedArticle.article;

	return (
		<div
			className="featured-article-bg"
			style={{
				backgroundImage: `linear-gradient(rgba(40, 40, 40, .3), rgba(40, 40, 40, .7)), url(${heroImage.file.url})`,
			}}
		>
			<section className="section">
				<div className="container">
					<div className="featured-article">
						<div className="text text--small text--white text--weight-semi-bold">
							{featuredArticleCategory}
						</div>
						<div className="featured-article__title">
							{featuredArticleTitle}
						</div>
						<div>
							<Link
								to={`/news/${featuredArticleSlug}`}
								className="btn btn--sm btn--white-orange"
							>
								Read
							</Link>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
}

export default FeaturedArticle;
